/* Variables */
:root {
  --purple: #A562A6;
  --red: #DB3626;
  --orange: #EB9922;
  --teal: #66C5C3;
  --blue: #68B6E5;
  --background: #10112E;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./components/css/assets/BLOB 1.png'), var(--background) no-repeat;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-rendering: optimizeLegibility;
}

body .ui.card,
body .ui.cards > .card {
  /* box-shadow: 0 0 15px 0 #e4e4e4;
=======
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #75bfa9;
    ;
}

body .ui.card, body .ui.cards>.card {
    /* box-shadow: 0 0 15px 0 #e4e4e4;
>>>>>>> master
  border-radius: 7%;
  transition: transform .2s; */
}

body .ui.card:hover {
    /* transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
