.member-cards-container {
  padding: 25px;
  background: rgba(255, 255, 255, 0.22);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.add-card {
  display: flex;
  align-items: center;
  justify-content: center;
}