@font-face {
  font-family: Lekton-Regular;
  src: url("./fonts/lekton/Lekton-Regular.ttf");
}

@font-face {
  font-family: Lekton-Bold;
  src: url("./fonts/lekton/Lekton-Bold.ttf");
}

@font-face {
  font-family: Quicksand-Bold;
  src: url("./fonts/quicksand/Quicksand-Bold.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  src: url("./fonts/roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("./fonts/roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: Roboto-Bold;
  src: url("./fonts/roboto/Roboto-Bold.ttf");
}

.UserCard-container {
  padding-top: 2%;
  width: 100%;
}

.button {
  text-align: center;
  padding-left: 20%;
}

.content {
  text-align: left;
}

.card-description {
  padding-bottom: 3%;
}

.card-container {
  display: flex;
  justify-content: center;
  padding-bottom: 2%;
}

.contact-button {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

body .ui.card,
body .ui.cards > .card {
  transition: transform 0.2s;
}

body .ui.card:hover {
  transform: scale(1.05);
}

.rectangle1 {
  background: #c3bbcd;
  box-sizing: border-box;
  width: 100%;
  height: 25px;
  border-bottom: 2px solid #867a96;
  border-radius: 0 !important;
}

.ui.button.contact {
  color: white;
  background: var(--teal);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px 0px;
  font-family: Roboto-Medium;
  font-weight: 500;
  font-style: normal;
  width: 45%;
  font-size: 13px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.ui.button.teamUp {
  color: white;
  display: flex;
  background: var(--purple);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  margin-left: 5px;
  width: 45%;
  font-size: 13px;
  font-family: Roboto-Medium;
  font-weight: 500;
  font-style: normal;
  justify-content: center;
}

.ui.label.labelStyle {
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px 0px;
  font-family: Quicksand-Bold;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.ui.card.card1 {
  border-radius: 15px;
  box-shadow: -20px 20px 0px -8px rgba(255, 255, 255, 0.3); 
  margin: 15px;
  min-width: 270px;
  width: 270px;
  min-height: 382px;
  max-height: 382px;
}

.ui.card > .content > .header:not(.ui),
.ui.cards > .card > .content > .header:not(.ui) {
  text-align: center;
  padding-top: 10px;
  color: var(--purple);
  font-family: Roboto-Bold;
  font-weight: 400;
  font-size: 20px;
}

.ui.divider {
  border: 1px solid var(--purple) !important;
  margin-top: 5px !important;
}

.ui.card .meta,
.ui.cards > .card .meta {
  color: #10112E;
  padding-left: 15px;
  font-family: Roboto-Medium;
}

.ui.container.about {
  overflow: auto;
  max-height: 80px;
  color: #10112E;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-family: Roboto-Medium;
}

.ui.container.skills {
  overflow: auto;
  max-height: 80px;
}

/* Overriding default scrollbar */
.ui.container.skills::-webkit-scrollbar {
  width: 9px;
}

.ui.container.skills::-webkit-scrollbar-track {
  background: rgba(165, 98, 166, 0.4);
  border-radius: 4.5px;
}

.ui.container.skills::-webkit-scrollbar-thumb {
  background: var(--purple)
}
