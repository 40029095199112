.headers {
  padding-top: 5%;
}

.notification-pane {
  min-width: 800% !important;
}

.ui.popup.fitted {
  padding: 0px;
}
.ui.popup.fitted > .ui.menu {
  border: none;
}
.ui.menu.scrolling {
  max-height: 5%;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 595px) {
  .header-name, .desktopTitles {
    display: none;
  }

  .innerMobileTitles {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}

@media (min-width: 595px) {
  .mobileTitles {
    display: none;
  }
}

@font-face {
  font-family: Quicksand-Bold;
  src: url("./fonts/quicksand/Quicksand-Bold.ttf");
}

.ui.inverted.popup {
  color: black
}

.content {
  color: black
}

/* i.icon:before {
  margin-left: 10px;
} */


element.style {
  color: var(--purple);
}
.ui.secondary.menu .item {
  padding-left: 0;
  padding-right: 0;
  padding-top: .78571429em;
  padding-bottom: .92857143em;
}

.ui.bottom.popup:before {
  background: var(--purple);
}

.ui.top.popup:before {
  background-color: var(--purple);
}

.ui.visible.popup {
  background-color: var(--purple);
}

.ui.menu:not(.vertical) > .menu {
  display: flex;
  padding-right: 2%;
}

.ui.menu .ui.dropdown.item .menu .item:not(.filtered) {
  margin-left: 10px;
  margin-right: 10px;
}

.ui.visible.popup {
  background-color: var(--purple);
  border-radius: 15px;
}
