@font-face {
  font-family: Lekton-Regular;
  src: url("./fonts/lekton/Lekton-Regular.ttf");
}

@font-face {
  font-family: Lekton-Bold;
  src: url("./fonts/lekton/Lekton-Bold.ttf");
}

@font-face {
  font-family: Quicksand-Bold;
  src: url("./fonts/quicksand/Quicksand-Bold.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("./fonts/roboto/Roboto-Medium.ttf");
}

.UserCard-container {
  padding-top: 2%;
  width: 100%;
}

.button {
  text-align: center;
  padding-left: 20%;
}

.content {
  text-align: left;
}

.card-description {
  padding-bottom: 3%;
}

.card-container {
  display: flex;
  justify-content: center;
  padding-bottom: 2%;
}

.contact-button {
  width: 100%;
}

body ::-webkit-scrollbar-track {
  background: var(--purple);
}

.ui.card.card1 {
  box-shadow: -20px 20px 0px -8px rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding-bottom: 30px;
}

.ui.container.name {
  color: var(--purple);
  font-family: Quicksand-Bold;
  font-size: 20px;
  text-align: center;
  overflow-y: hidden;
}

.ui.divider {
  border: 1px solid var(--purple) !important;
}

.ui.button.joinTeam {
  color: white;
  background: var(--teal);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px 0px;
  margin-left: 5px;
  font: 'Roboto-Medium';
  font-weight: 400;
  display: flex;
  justify-content: center;
}
.ui.container.seeking {
  overflow: auto;
  max-height: 42px;
  color: var(--background);
  padding-left: 15px;
  font-family: Quicksand-Bold;
}

.ui.container.bio {
  overflow: auto;
  max-height: 42px;
  color: var(--background);
  padding-left: 15px;
  font-family: Quicksand-Bold;
}

.ui.button.newTeam {
  color: white;
  background: var(--purple);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px 0px;
  margin-left: 5px;
  width: 50%;
  font-family: 'Roboto-Medium';
  font-weight: 400;
  margin-left: 65px;
  display: flex;
  justify-content: center;
}

.ui.container.skills {
  overflow: auto;
  max-height: 80px;
}