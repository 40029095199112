.ui.button.done {
    margin-left: 173px;
}

.ui.button.here {
    background: rgba(255, 255, 255, 0.22);
    color: white;
    margin: 10px;
    font-family: Quicksand-Bold;
    border-radius: 12px;
}

.ui.button.cancel {
    background: rgba(255, 255, 255, 0.22);
    color: white;
    margin: 10px;
    font-family: Quicksand-Bold;
    border-radius: 12px;
}

.ui.modal.confirmModal {
    max-width: 500px !important;
    background-color: transparent !important;
}

.dimmer { background-color: transparent ; }