@font-face {
  font-family: Lekton-Bold;
  src: url("./fonts/lekton/Lekton-Bold.ttf");
}

@font-face {
  font-family: Roboto-Medium;
  src: url("./fonts/roboto/Roboto-Medium.ttf");
}

.first-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 3%;
  align-items: flex-start;
}

@media (max-width: 595px) {
  .first-row {
    max-width: 309px;
  }
}

.ui.button.ask-to-join {
  font-family: Roboto-Medium;
  font-weight: 500;
  background: var(--red);
	color: white;
}

.ui.button.leaveTeam {
  font-family: Roboto-Medium;
  font-weight: 500;
  background: var(--red);
  color: white;
  margin-left: 25px;
}

.no-team-heading {
  color: white;
  font-family: Quicksand-Bold;
}

.noTeam-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.team-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

.first-col {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}

.second-col {
  /* max-width: 500px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 5px;
}

#header {
  display: inline-flex;
  width: 100%;
  margin-left: 25px;
  justify-content: center;
  align-items: center;
}

#field {
  background: transparent;
  border: none;
  font-family: Lekton-Bold;
  text-align: center;
  padding-left: 2.6em;
}

#alert {
  padding: 1px;
}

.ui.input > input {
  padding: 0;
  background: #656cae;
}