@font-face {
  font-family: Chango-Regular;
  src: url("./fonts/chango/Chango-Regular.ttf");
}

@font-face {
  font-family: Lekton-Regular;
  src: url("./fonts/lekton/Lekton-Regular.ttf");
}

@font-face {
  font-family: Lekton-Bold;
  src: url("./fonts/lekton/Lekton-Bold.ttf");
}

@font-face {
  font-family: Quicksand-Bold;
  src: url("./fonts/quicksand/Quicksand-Bold.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  src: url("./fonts/roboto/Roboto-Regular.ttf");
}

textarea::placeholder {
  color: var(--purple);
  font-family: Roboto-Regular;
  font-weight: 700;
}

.modalHeader {
  text-align: center;
  color: white;
  font-size: 30px;
  font-family: Lekton-Bold;
}

.modal1Margins {
  margin-right: 80px;
  margin-left: 80px;
}

.requestMessage {
  margin-top: 60px;
  color: white;
  font-family: Quicksand-Bold;
  font-size: 15px;
}

.requestMessageBody {
  border: transparent;
  border-radius: 15px;
  padding: 20px;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  color: var(--purple);
  font-family: Roboto-Regular;
  background: white;
}

.projectIdea {
  margin-top: 30px;
  color: var(--purple);
  font-family: Roboto-Regular;
  font-size: 15px;
}

.projectIdeaBody {
  border: transparent;
  border-radius: 15px;
  padding: 20px;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  color: var(--purple);
  font-family: Roboto-Regular;
  background: white;
}

.flex-container1 {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}

.ui-black-basic-button {
  border-radius: 20px;
  font-size: 15px;
  padding: 12px;
}

.flex-container2 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonMargin {
  margin: 10px;
}

#writeAMessage {
  margin-top: 25px;
  border-radius: 15px;
  padding: 15px;
  border-color: transparent;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  display: block;
  margin-left: auto;
  margin-right: auto;
  resize: none;
  color: var(--purple);
  font-family: Roboto-Regular;
  width: 500px;
  height: 150px;
}

@media (max-width: 595px) {
  #writeAMessage {
    max-width: 300px;
  }
}

@media (max-width: 370px) {
  #writeAMessage {
    max-width: 250px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
}

.modal3Column {
  display: flex;
  flex-direction: column;
}

.modal3Column1 {
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 25px;
}

.modal3Column2 {
  display: inline-block;
  flex-direction: column;
  text-align: center;
  flex: 1;
  min-width: 270px;
  max-width: 418px;
}

.modal3Column3 {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
}

.cardName {
  text-align: center;
  font-size: 30px;
}

.cardInfo {
  text-align: left;
  margin-top: 50px;
  font-size: 17px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 40px;
}

.user1RequestMessage {
  border: transparent;
  border-radius: 15px;
  padding: 20px;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  color: var(--purple);
  font-family: Roboto-Regular;
  background: white;
}

.user1FirstName {
  color: var(--purple);
  font-family: Roboto-Regular;
  font-size: 15px;
}

.user1ProjectIdea {
  border: transparent;
  border-radius: 15px;
  padding: 20px;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  color: var(--purple);
  font-family: Roboto-Regular;
  background: white;
}

.flex-container-modal3 {
  display: flex;
  justify-content: center;
}

.modal3Button {
  margin: 10px;
}

.modal4-column {
  display: flex;
  flex-direction: column;
}

.modal4-column1 {
  margin: 25px;
  margin-top: 0px;
}

.modal4-column2 {
  margin-left: 25px;
  margin-right: 25px;
  text-align: center;
}

#introduceYourself {
  border-radius: 15px;
  padding-left: 15px;
  padding-top: 15px;
  border-color: transparent;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  color: var(--purple);
  font-family: Roboto-Regular;
  resize: none;
  width: 100%;
}

#describeProject {
  margin-top: 5%;
  padding-left: 15px;
  padding-top: 15px;
  border-color: transparent;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  color: var(--purple);
  font-family: Roboto-Regular;
  resize: none;
  width: 100%;
}

.ui.modal {
  background: none;
}


.ui.modal > .content {
  background: url("./assets/blob2.png") no-repeat top right, var(--background);
  border-radius: 16px !important;
  box-shadow: 0px 0px 8px #FFFFFF;
}

.ui.button.submit {
  border-radius: 12px;
  color: white;
  background: var(--red);
  font-family: Roboto-Regular;
  font-weight: 700;
}

.ui.button.accept {
  margin-left: 50px;
}

.checkingModal-column {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.checkingModal-row2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.error {
    text-align: center;
    padding-top: 2%;
}