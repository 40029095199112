.member-cards-container {
  padding: 30px;
  margin: 20px
}

.emptyCard {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  border: solid 2.5px white;
  box-shadow: -20px 20px 0px -8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin: 15px;
  min-width: 270px;
  width: 270px;
  min-height: 382px;
  max-height: 382px;
}

i.icon.plus:before {
  color: white
}