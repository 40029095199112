.ui.horizontal.label, .ui.horizontal.labels .label {
  margin: 0 .5em .5em 0;
}

#chosen-tags {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-flow: wrap;
    margin-bottom: 1em;
}